import React from "react";
import { Viewer } from '@react-pdf-viewer/core';

import pdf_CFD from "../../bdd/pdfs/les_fondamentaux_de_la_CFD.pdf"

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

export default function Fondamentaux() {
    const toolbarPluginInstance = toolbarPlugin({
        selectionModePlugin: {
            selectionMode: SelectionMode.Hand
        },
        searchPlugin: {
            keyword: 'PDF'
        }
    });


    const { Toolbar } = toolbarPluginInstance;

    return (
        <main>
            <div className="fondamentaux">
                <Toolbar />
                <Viewer initialPage={1} plugins={[toolbarPluginInstance]} fileUrl={pdf_CFD} />
            </div>
        </main>
    )
}