import React from "react";
import Logo_FLYNAERO from "../../images/logos/Flynaero-solo.svg"

export default function Accompagnement() {
    return (
        <div className="accompagnement">
            <div className="accompagnement__card">
                <p className="text-cartouche">FLYNAERO vous accompagne dans vos projets en mécanique des fluides grâce à sa méthodologie personnalisée, méthode révolutionnaire basée sur le DPSM.</p>
                <p className="text-cartouche">Notre équipe s’engage sur les résultats avant le début des travaux.</p>
            </div>
            <div className="accompagnement__titles">
                <div className="accompagnement__titles__title">
                    <h2 className="titre-cartouche">Un accompagnement sur-mesure avec un engagement de résultat</h2>
                </div>
                <img src={Logo_FLYNAERO} alt="Logo de FLYNAERO" />
            </div>
        </div>
    )
}