import React, { useRef, useState } from "react";
import { Marker, MapContainer, TileLayer, Popup } from "react-leaflet"
import oms from "../../oms-providers"
import "leaflet/dist/leaflet.css"

import { IconLocation } from "../../oms-iconLocation";
import Logo_FLYNAERO from "../../images/logos/Logo_FLYNAERO.jpg"


export default function ContactMap ({ isActive, data, map }) {

    const [center, setCenter] = useState({lat: 48.746360492638544, lng: 2.354342212495135})
    const [flyn, setFlyn ] = useState({lat: 48.746023372097675, lng: 2.354418016375348})
    const ZOOM_LEVEL = 17
    const mapRef = useRef(null)

    return (
        <div className="map__container">
            <div className="map__container__text">
                <h1 className="titre-cartouche">FLYNAREO</h1>
                <p className="text-cartouche">43, avenue Robert Schuman</p>
                <p className="text-cartouche">94150 Rungis</p>
            </div>
            <MapContainer
                center={center}
                zoom={ZOOM_LEVEL}
                ref={mapRef}
            >
                <TileLayer url={oms.maptiler.url}  />
                <Marker position={flyn} icon={IconLocation}> 
                    <Popup>
                        <div className="popup__title">
                            <img src={Logo_FLYNAERO} alt="Logo FLYNAERO" />
                        </div>
                        <span>Parc d’affaires ICADE</span> <br />
                        <span>43, avenue Robert Schuman 94150 Rungis</span>
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}