import L from "leaflet"
import Icon from "./images/icones/venue_location_icon.svg"

export const IconLocation = L.icon({
    iconUrl: Icon,
    iconRetinaUrl: Icon,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [70,70],
    className: "Leaflet-venue-icon",
})