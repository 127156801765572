import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as emailjs from 'emailjs-com';

const ContactForm = () => {

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      entreprise: "",
      phoneNumber: "",
      acceptTerms: false,
    },
    validationSchema: Yup.object({
        name: Yup.string()
            .min(2,"Nom trop court")
            .max(20, "20 caractères maximum")
            .required("Champ obligatoire"),
        email: Yup.string()
            .email("Adresse email invalide")
            .required("Champ obligatoire"),
        message: Yup.string()
            .min(15,"15 caractères minimum")
            .max(300, "300 caractères maximum")
            .required("Champ obligatoire"),
        entreprise: Yup.string()
            .max(25, "25 caractères maximum")
            .required("Champ obligatoire"),
        phoneNumber: Yup.string()
            .max(15, "Numéro invalide")
            .required("Champ obligatoire"),
    }),
    onSubmit: (values) => {
      try{
        emailjs.send('service_2v1qn2w', 'template_9fygnsc', values, '9l1i1zd_x8bsO1tNj')
          .then(() => {
            alert('Message Envoyé')
            formik.handleReset()
          });
      }
      catch {
        alert('Le formulaire contient une ou plusieurs erreurs')
      }
    },
  });
  return (
    <form className="contact-form" onSubmit={formik.handleSubmit}>
      <div className="form-fields">
        <label htmlFor="name">Nom :</label>
        <input
          id="name"
          name="name"
          type="text"
          placeholder="Entrez un nom"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="errors">{formik.errors.name}</div>
        ) : null}
      </div>
      
      <div className="form-fields">
        <label htmlFor="email">Email :</label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="Entrez une adresse email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="errors">{formik.errors.email}</div>
        ) : null}
      </div>

    <div className="form-fields field-message">
      <label htmlFor="message">Message :</label>
      <textarea
          id="message"
          name="message"
          placeholder="Entrez votre message"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
      />
      {formik.touched.message && formik.errors.message ? (
      <div className="errors">{formik.errors.message}</div>
      ) : null}
    </div>

    <div className="form-fields">
      <label htmlFor="entreprise">Société :</label>
      <input
        id="entreprise"
        name="entreprise"
        type="text"
        placeholder="Entrez une société"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.entreprise}
      />
      {formik.touched.entreprise && formik.errors.entreprise ? (
        <div className="errors">{formik.errors.entreprise}</div>
      ) : null}
    </div>

    <div className="form-fields">
      <label htmlFor="phoneNumber">Tél. bureau :</label>
      <input
          id="phoneNumber"
          name="phoneNumber"
          type="text"
          placeholder="Entrez un numéro de téléphone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phoneNumber}
      />
        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
          <div className="errors">{formik.errors.phoneNumber}</div>
        ) : null}
    </div>

      <button className="btn-submit" type="submit">Envoyer</button>
    </form>
  );
};

export default ContactForm;
