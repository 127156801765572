import React from "react";

export default function History(props) {
    return (
        <div className="history">
            <div className="history__cartouche">
                <h2 className="titre-cartouche">{props.title}</h2>
                <div className="history__cartouche__paragrafs text-cartouche">
                    {props.paragrafs.map ((paragraf, index) => {
                        return <p key={index}>{paragraf}</p>
                    })}
                </div>
            </div>
        </div>
    )
}