import React from "react";

import ico_linkedIn from "../../images/icones/icons8-linkedin.svg"

export default function Employee(props) {
    return (
        <div className="employee__card">
            <div className="employee__card__title">
                <img src={ props.image ? process.env.PUBLIC_URL + "/assets/images-employees/" + props.image : process.env.PUBLIC_URL + "/assets/images-employees/none.png"} alt="Employé" />
                <h3>{props.name}</h3>
                <h4>{props.hierarchy}</h4>
                <div className="employee__card__title__socials">
                    {props.linkedIN 
                        ? 
                            <a href={props.linkedIN}>
                                <img src={ico_linkedIn} alt="Icone LinkedIn" />
                            </a>
                        :
                            ""
                    }
                </div>
            </div>
            <div className="employee__card__description">
                <p>{props.description}</p>
                
            </div>
        </div>
    )
}