import { createContext, useState } from "react";

const MenuContext = createContext();

export function MenuProvider({children}) {

    const [showBurger, setShowBurger] = useState(false);

    const handleShowBurger = () => {
        setShowBurger(!showBurger);
    }

    const [ EtudesScreen , setEtudesScreen ] = useState(false);

    const SetEtudesScreen = () => {
        setEtudesScreen(true);
    }

    const UnsetEtudesScreen = () => {
        setEtudesScreen(false);
    }

    return (
        <MenuContext.Provider value={{ showBurger, handleShowBurger, EtudesScreen, SetEtudesScreen, UnsetEtudesScreen }}>{children}</MenuContext.Provider>
    )
}

export default MenuContext