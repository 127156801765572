import React from "react";
import ENSCNRS from "./_PartenariatENSCNRS";

export default function Partenariat() {
    return(
        <main>
                <ENSCNRS />
                <div className="fond"></div>
        </main>
    )
}