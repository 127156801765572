import React from "react";
import Offer from "./_RecrutementOffer";
import OffersList from "../../bdd/jobs.json"

export default function Recrutement() {
    return(
        <main className="recrutement">
            <div className="recrutement__offers">
                {OffersList.map(offer => {
                    return <Offer key={offer.id} {...offer} />
                })}
            </div>
        </main>
    )
}