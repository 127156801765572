import React, { useContext } from "react";
import { NavLink } from 'react-router-dom';
import MenuContext from "./MenuContext";

export default function BtnEasyFluid() {
    const {handleShowBurger} = useContext(MenuContext);
    return (
        <div className="btn-easy-fluid">
            <h3 className="titre-cartouche">Notre Logiciel</h3>
            <h3 className="titre-cartouche">révolutionnaire</h3>
            <h1 className="titre-cartouche"><NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes/Easy-Fluid" activeClassName="active" onClick={handleShowBurger}>Easy-Fluid</NavLink></h1>
        </div>
    )
}