import React from "react";

function BtnNavEtudes (props) {
    return (
        <div className="btn-etudes">
            <img src={props.img} alt={props.alt} />
            <p>{props.name}</p>
        </div>
    )
}

export default BtnNavEtudes