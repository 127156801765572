import React from "react";
import img_unisex from "../../images/flynaero/icons8-unisex-96.png";

export default function Offer(props) {

    return (
        <div className="offer__card">
            <div className="offer__card__header titre-cartouche">
                <img src={img_unisex} alt="" />
                <h3>{props.title}</h3>
            </div>
            <div className="offer__card__paragrafs text-cartouche">
                {props.paragrafs.map ((paragraf, index) => {
                    return <p key={index}>{paragraf}</p>
                })}
            </div>
            <div className="offer__card__footer">
                <p>Type de contract: {props.contractType}</p>
                <p>Salaire: {props.salary}</p>
                <div className="offer__bonuses">
                    {props.bonuses.map ((bonus, index) => {
                        return <p key={index}>{bonus}</p>
                    })}
                </div>
                <p>Localisation: {props.localisation}</p>
            </div>
            <a className="offer__card__button" href={"MAILTO:test@test.com?subject=Recrutement Flynaero.com | " + props.title}>POSTULER</a>
        </div>
    )
}