import React from "react";

export default function ActualityView(props) {
    return (
        <div className="actuality__modal">
            <div className="actuality__modal__header">
                <img className="actuality__modal__header__image" src={ props.image ? process.env.PUBLIC_URL + "/assets/images-actus/" + props.image : process.env.PUBLIC_URL + "/assets/images-actus/none.png"} alt="actu" />
                <h2 className="actuality__modal__header__title titre-cartouche">{props.title}</h2>
            </div>
            <div className="actuality__modal__paragrafs">
                {props.paragrafs.map ((paragraf, index) => {
                        return <p key={index}>{paragraf}</p>
                    })}
            </div>
            <span className="actuality__modal__span">{props.autor} le {props.date}</span>
        </div>
    )
}