import React from "react";

import Actuality from "./_Actuality";

import NewsList from "../../bdd/news.json";


export default function Actualities() {
    return (
        <main>
            <div className="actualities__grid">
                {NewsList.map(news => {
                    return <Actuality key={news.id}{...news} />
                })}
            </div>
        </main>
    )
}