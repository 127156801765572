import React from "react";
import ReactPlayer from 'react-player'

export default function VideoCard (props) {

    return (
        <div className="video__card">
            <ReactPlayer url={props.url} width="100%" />
            <h3 className="titre-cartouche">{props.title}</h3>
        </div>
    )
}