import React from "react";
import Employees from "../../bdd/employees.json"

import Employee from "./_NousEmployees";

export default function Nous() {
    return(
        <main className="nous">
            <div className="employees">
                <div className="employees__grid">
                    {Employees.map(employe => {
                        return <Employee key={employe.id} {...employe} />
                    })}
                </div>
            </div>
        </main>
    )
}