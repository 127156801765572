import React from "react";
import Picture_fluidBoat from "../../images/bureauDetudes/fluide_bateau.png"

export default function Revolution() {
    return (
        <div className="revolution">
            <div className="revolution__card">
                <h2 className="titre-cartouche">UNE MÉTHODE RÉVOLUTIONNAIRE</h2>
                <p className="text-cartouche">FLYNAERO est un bureau d'études spécialisé dans la simulation numérique en mécanique des fluides CFD (Computational Fluid Dynamics). Nous sommes à votre écoute avec nos ingénieurs pour des études CFD complètes ainsi qu’un service de conseil dans tous les domaines de la mécanique des fluides. S’appuyant sur une méthode révolutionnaire en rupture avec les logiciels existants. La méthode DPSM</p>
            </div>
            <h3 className="titre-cartouche">Optimisez vos procédés industriels, dans des secteurs d’activité qui sont les nôtres.</h3>
            <div className="revolution__picture">
                <img src={Picture_fluidBoat} alt="Analyse de fluide coque de bateau" />
                <p className="text-cartouche">De l’aéronautique en passant par l’industrie navale, Notre équipe d’ingénieurs issue du CNRS, ENS, Paris Saclay vous accompagneront dans la résolution de problématiques industrielles impliquant des fluides.</p>
            </div>
        </div>
    )
}