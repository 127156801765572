import React from "react";

import EasyFluid from "./_EasyFluid";

export default function EasyFluidPage() {
    return (
        <main>
            <EasyFluid />
        </main>
    )
}