import React from "react";
import VideoCard from "./_VideoCard";

import VideoList from "../../bdd/realisations.json"

export default function Realisations() {
    return (
        <main>
            <div className="realisations__grid">
                {VideoList.map(video =>{
                    return <VideoCard key={video.id}{...video} />
                })}
            </div>
        </main>
    )
}