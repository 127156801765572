import React, { useContext, useEffect } from "react";

import MenuContext from "../MenuContext";

import Bande_FLYN from "../../images/bureauDetudes/bande-flyn.jpg";

import HomeFondamentaux from "./_HomeFondamentaux";
import Revolution from "./_HomeRevolution";
import Accompagnement from "./_HomeAccompagnement";
import EasyFluid from "./_EasyFluid";
import ENSCNRS from "../flynaero/_PartenariatENSCNRS";

export default function BEHome () {
    const {SetEtudesScreen} = useContext(MenuContext)
    useEffect(() => {
        SetEtudesScreen(true)
    })
    return (
        <>
            <main>
                <div className="etudes__header">
                    <div className="etudes__header__title">
                        <h2 className="title titre-cartouche">Votre Bureau d'études CFD</h2>
                    </div>
                </div>
                <div className="etudes__bande">
                    <img src={Bande_FLYN} alt="Bande photos Flynaero" />
                </div>
                <HomeFondamentaux />
                <ENSCNRS />
                <Revolution />
                <Accompagnement />
                <EasyFluid />
            </main> 
        </>

    )
}