import React from "react";
import ContactForm from "./_ContactForm";
import Map from "./_ContactMap"

export default function Contact() {
    return(
        <main name="bloc-contact" className="contact">
            <div className="contact__form">
                <div className="contact__form__title titre-cartouche">
                    <h4>Pour aller plus loin :</h4>
                    <h3>Contactez nous...</h3>
                </div>
                <div className="contact__form__inputs">
                    <ContactForm />
                </div>
            </div>
            <div className="contact__map">
                <Map />
            </div>
        </main>
    )
}